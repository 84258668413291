@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Architects+Daughter&family=Caveat+Brush&family=Jost&family=Monoton&family=Poppins:wght@300&display=swap');

/* @import url('https://rs-bucket-s3.s3.ap-south-1.amazonaws.com/Modeseven-L3n5.ttf'); */

@font-face {
  font-family: 'Modeseven';
  src: url('https://rs-bucket-s3.s3.ap-south-1.amazonaws.com/Modeseven-L3n5.ttf');
}

@font-face {
  font-family: matrix;
  src: url('https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/misc/matrix+code+nfi.ttf');
}

@font-face {
  font-family: hackbot;
  src: url('https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/misc/hackbot.ttf');
}

@font-face {
  font-family: Xirod;
  src: url('https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/misc/xirod.ttf');
}

html {
  cursor: url(./Assets/Cursor/main.svg), auto !important;
  font-family: Helvetica, sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

div and :not(ToolTip) {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}


.scrollbar::-webkit-scrollbar {
  display: block;
  width: 2px;
}


.scrollbar::-webkit-scrollbar-track {
  display: block;
  background: #f5f5f530;
}


.scrollbar::-webkit-scrollbar-thumb {
  display: block;
  width: 2px;
  background: #F5F5F580;
}

* {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  -webkit-user-drag: none;
  user-zoom: fixed;

  /* Standard syntax */
}

/* * {
  cursor: url("./Assets/Images/cursor.png"), auto;
} */