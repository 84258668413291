.matrix {
    z-index: 12;
    background-color: black;
    transition-delay: 0.3s;
}

.matrix::before {
    position: absolute;
    content: attr(data-text);
    text-shadow: 0px 0px 40px #008F11;
    filter: blur(10px) brightness(0.2);
    animation: flicker 2s alternate forwards infinite;
    animation-delay: 2s;
}

@keyframes flicker {
    0% {
        filter: blur(5px) brightness(1);
    }

    3% {
        filter: blur(5px) brightness(0.2);
    }

    6% {
        filter: blur(5px) brightness(0.2);
    }

    7% {
        filter: blur(5px) brightness(1);
    }

    8% {
        filter: blur(5px) brightness(0.2);
    }

    9% {
        filter: blur(5px) brightness(1);
    }

    10% {
        filter: blur(5px) brightness(0.2);
    }

    20% {
        filter: blur(5px) brightness(1);
    }

    50% {
        filter: blur(5px) brightness(1);
    }

    70% {
        filter: blur(5px) brightness(1);
    }

    100% {
        filter: blur(5px) brightness(1);
    }
}