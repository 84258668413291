.modal {
    position: fixed;
    top: 0;
    left: 0;
    animation: in 0.5s ease-in-out 1;
}

.modalClose {
    position: fixed;
    top: 0;
    left: 0;
    animation: out 0.5s ease-in-out 1;
}

@keyframes in {
    0% {
        transform: translateX(100%);
        scale: 0;
        opacity: 0.1;
        width: 0%;
        height: 0%;
    }

    100% {
        transform: translateX(0%);
        scale: 1;
        opacity: 1;
        width: 100%;
        height: 100%;
    }
}

@keyframes out {
    0% {
        transform: translateX(0%);
        scale: 1;
        opacity: 1;
        width: 100%;
        height: 100%;
    }

    100% {
        transform: translateX(100%);
        scale: 0;
        opacity: 0.1;

        width: 0%;
        height: 0%;
    }

}

.task {
    animation: taskin 0.3s ease-in-out 1;
}

.taskitems {
    animation: items 0.3s ease-in-out 1;
}


@keyframes taskin {
    0% {
        transform: translateY(50%);
        opacity: 0.1;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes items {
    0% {
        transform: translateY(-50%);
        /* opacity: 0.5; */
    }

    100% {
        transform: translateY(0);
        /* opacity: 1; */
    }

}

.matrix {
    animation: matrix 0.5s ease-in-out 1;
    transition-property: all;
    background-color: black;
}

@keyframes matrix {
    0% {
        opacity: 0;
    }

    1% {
        background-color: white;
    }

    2% {
        background-color: black;
    }

    10% {
        background-color: white;
    }

    11% {
        background-color: black;
        opacity: 0;
    }

    60% {
        background-color: white;
    }

    61% {
        background-color: black;
    }

    100% {
        opacity: 1;
    }
}