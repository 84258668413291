.button {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 24px;
    border: 0;
    border-radius: 3rem;
    color: #f7f7f7;
    background-color: #d62959;
    cursor: pointer;
    width: 200px;
    height: 56px;
}

.text {
    position: absolute;
    left: 64px;
    display: block;
    white-space: nowrap;
}

.icon {
    font-size: 30px;
}

.sending .text {
    animation: textAnimation 5s both;
}

.sending .icon {
    animation: iconAnimation 5s both;
}

@keyframes textAnimation {
    0%, 30% {
        translate: 0 0;
    }

    39.9% {
        translate: 0 100px;
    }

    40% {
        translate: 300px 100px;
    }

    40.1% {
        translate: 300px -60px;
    }

    40.2% {
        translate: 33px -60px;
    }

    45%, 95% {
        translate: 33px 0;
    }

    100% {
        translate: 0 0;
    }

}

@keyframes iconAnimation {
    0%, 5% {
        translate: 0 0;
    }

    20%, 30% {
        translate: -250px 0;
    }

    40% {
        translate: 200px 0;
        scale: 2;
    }

    40.1% {
        translate: 200px -60px;
        scale: 1;
    }

    40.2% {
        translate: 34px -60px;
    }

    45%, 95% {
        translate: 34px 0;
    }

    100% {
        translate: 0 0;
    }

}